import React from 'react';
import {ReactComponent as LogoIcon} from '../icons/logo.svg';
import { Link } from "react-router-dom";
import { withRouter } from 'react-router';
import './menu.css';

const MenuComponent = () => {
    
    return (
        <div className="iq__menu__content">
            <div className="iq__menu">
                <Link to="/" className="iq__menu__logo">
                    <LogoIcon />
                </Link>
            </div>
        </div>
    )
}

export default withRouter(MenuComponent);