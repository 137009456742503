import React from 'react';
import { useTranslation } from "react-i18next";
import Typography from '@material-ui/core/Typography';
import doctor from '../../../images/doctor.png';
import family from '../../../images/family.png';
import attention from '../../../images/attention.png';
import psychologist from '../../../images/psychologist.png';
import "../../../i18n/i18n";
import './services.css';


const ServicesComponent = () => {
    const services = [
        {
            image: doctor,
            text: 'services_doctors'
        },
        {
            image: psychologist,
            text: 'services_psychologists'
        },
        {
            image: attention,
            text: 'services_attention'
        },
        {
            image: family,
            text: 'services_family'
        },
    ];
    const { t } = useTranslation();
    return (
        <div className="iq__services">
            <Typography variant="h3">{t("services_title")}</Typography>
            <Typography variant="subtitle2">{t("services_description")}</Typography>
            <div className="iq__services__container">
                {services.map((item, index) => 
                    <div className="iq__services__card" key={index}>
                        <img src={item.image} alt={t(item.text)}/>
                        <Typography variant="subtitle1">{t(item.text)}</Typography>
                    </div>
                )}
            </div>
        </div>
    )
}

export default ServicesComponent;