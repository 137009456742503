import {Helmet} from "react-helmet";
import { useTranslation } from "react-i18next";
import ServicesComponent from './services/services'
import MenuComponent from "../../menu/menu";
import Typography from '@material-ui/core/Typography';
import React from 'react';
import init from '../../images/init.png';
import "../../i18n/i18n";
import './home.css';


const HomeComponent = () => {
    const { t } = useTranslation();
    return (
        <div>
            <Helmet>
                <title>{t("innoverso")}</title>
                <meta name="description" content={t("home_description")} />
                <link rel="canonical" href="https://innoverso.io"></link>
            </Helmet>
            <MenuComponent />
            <div className="iq__home">
                <div className="iq__home__init">
                    <Typography variant="h2">{t("home_title")}</Typography>
                    <Typography variant="subtitle1">{t("home_description")}</Typography>
                    <img className="iq__home__init__img" src={init} alt="doctor"/>
                </div>
                <ServicesComponent/>
            </div>
        </div>
    )
}

export default HomeComponent;